import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/sections/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function search (data) {
  return request({
    url: '/sections/search',
    method: 'post',
    data
  })
}
export function storeSections (data) {
  return request({
    url: '/sections',
    method: 'post',
    data
  })
}
export function getSections (id) {
  return request({
    url: '/sections/' + id,
    method: 'get'
  })
}
export function updateSections (id, data) {
  return request({
    url: '/sections/' + id,
    method: 'put',
    data
  })
}
export function destroySections (_id) {
  return request({
    url: '/sections/' + _id,
    method: 'delete'
  })
}
